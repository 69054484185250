<template>
  <h3 class="text-center">Последовательности</h3>
  <p>Для решения следующих задач нам будет необходим класс <strong>seq</strong>. Его код приведён ниже. Этот код нужно добавить в свой файл с решениями.</p>
  <Code lang="py" :code="`
    class seq:
        def __init__(self, *args):
            self.values = args
            self.idx = 0

        def next(self):
            if self.empty():
                raise ValueError('Считано больше элементов, чем есть в последовательности')
            value = self.values[self.idx]
            self.idx += 1
            return value

        def empty(self):
            return self.idx == len(self.values)
  `" />
  <div class="mt">Пока не обязательно понимать как этот код работает. Достаточно понимать как использовать класс <strong>seq</strong>. Для этого предлагаю ознакомиться с примером ниже:</div>
  <Code lang="py" :code="`
    a = seq(10, 20, 30)
    print(a.empty())
    print(a.next())
    print(a.next())
    print(a.next())
    print(a.empty())
  `" />
  <div class="mt">Результат:</div>
  <CodeResult :code="`
    False
    10
    20
    30
    True
  `" />
  <div class="mt">В первой строке создаётся последовательность из трёх чисел: 10, 20 и 30. Далее, с помощью метода empty мы проверяем, пустая ли последовательность. Если пустая, он верёт True, если есть ещё элементы - False. С помощью метода next мы можем брать следующее число из последовательности. При первом вызове next мы получаем 10, при втором 20, при третьем 30. Далее, как мы видим по коду, вызывается метод empty, который теперь возвращает True. Это значит, что из последовательности считаны все элементы.</div>
  <div class="mt">Можно перебрать все элементы последовательности с помощью цикла while:</div>
  <Code lang="py" :code="`
    a = seq(10, 20, 30)
    while not a.empty():
        print(a.next())
  `" />
  <div class="mt">Результат:</div>
  <CodeResult :code="`
    10
    20
    30
  `" />
  <div class="mt">В следующих задачах, где написано, что дана последовательность, имеется в виду, что дан вот такой объект класса <strong>seq</strong></div>
</template>

<script>
import Code from '@/components/ui/Code'
import CodeResult from '@/components/ui/CodeResult'

export default {
  components: { Code, CodeResult },
  props: ['task']
}
</script>
